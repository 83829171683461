import React from 'react';

import { Box, Grid } from '@core';

const PricingDesktop = ({ pricingCard: PricingCard, plans = [], isLoading }) => (
  <Grid.Container px={40}>
    <Grid.Row alignItems="flex-end" flexWrap="nowrap" justifyContent="center">
      {isLoading ? (
        <Grid.Col size={6}>
          <PricingCard isLoading />
        </Grid.Col>
      ) : (
        plans.map((plan, index) => (
          <Box
            order={plan.order?.desktop}
            width={plan.isHighlighted ? '40%' : '30%'}
            mx={12}
            key={plan.subscriptionPeriod || index}
          >
            <PricingCard data={plan} />
          </Box>
        ))
      )}
    </Grid.Row>
  </Grid.Container>
);

export default PricingDesktop;
