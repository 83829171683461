import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Show } from '@core';
import {
  Card,
  CurrencyPicker,
  LinkWrapper,
  SectionContainerSC as SectionContainer,
  Text,
} from '@components';
import { useIsClientSide } from '@hooks';
import { usePlansValuesContext } from '@context';
import { remapPlans } from '@shared/constants/pages/product-page-common';

import { PricingDesktop, PricingMobile } from './layouts';

const Footer = ({ text, button }) => (
  <Text.Body3 as="span" mt={{ _: 24, md: 40 }} mx={16} textAlign="center">
    {text}{' '}
    {button && (
      <LinkWrapper link={button.link}>
        <Button type="button" variant="text" {...button.buttonProps}>
          {button.title}
        </Button>
      </LinkWrapper>
    )}
  </Text.Body3>
);

const Pricing = ({
  title,
  description,
  footerProps,
  numberOfPlans, // this prop can come from page builder
  padding = { py: { _: 64, md: 88 } },
}) => {
  const { t } = useTranslation();

  const { isClientSide } = useIsClientSide();
  const { plans: plansFromContext, isLoading } = usePlansValuesContext();

  const renderedPlans = plansFromContext || []; // fallback while plans are loading in cms preview
  const plans = remapPlans(
    !numberOfPlans ? renderedPlans : [...renderedPlans].reverse().slice(0, numberOfPlans),
    t
  );

  return (
    <SectionContainer
      data-section-id="Pricing"
      title={title}
      description={description}
      wrapperProps={{ ...padding }}
      textWrapperProps={{ pb: { _: 16, md: 48, lg: 16 } }}
      footer={() => <Footer {...footerProps} />}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        position="relative"
        pb={64}
      >
        {isClientSide && (
          <CurrencyPicker
            titleDesktop={<strong>{`${t('checkout:inputFields.currency.label')}:`}</strong>}
            titleMobile={<strong>{`${t('checkout:inputFields.currency.label')}:`}</strong>}
          />
        )}
      </Box>

      <Show.TabletLarge>
        <PricingMobile
          plans={plans}
          pricingCard={Card.Pricing}
          isLoading={!isClientSide || isLoading}
        />
      </Show.TabletLarge>

      <Show.DesktopAndUp>
        <PricingDesktop
          plans={plans}
          pricingCard={Card.Pricing}
          isLoading={!isClientSide || isLoading}
        />
      </Show.DesktopAndUp>
    </SectionContainer>
  );
};

export default Pricing;
