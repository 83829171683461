import React from 'react';

import { Grid } from '@core';

const PricingMobile = ({ pricingCard: PricingCard, plans = [], isLoading }) => (
  <Grid.Container px={{ _: 16, md: 40 }}>
    <Grid.Row>
      {isLoading ? (
        <PricingCard isLoading />
      ) : (
        plans.map((plan, index) => (
          <Grid.Col
            order={plan.order?.mobile}
            size={12}
            mt={{ _: 32, sm: 16 }}
            key={plan.subscriptionPeriod || index}
          >
            <PricingCard data={plan} />
          </Grid.Col>
        ))
      )}
    </Grid.Row>
  </Grid.Container>
);

export default PricingMobile;
